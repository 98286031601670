<template>
    <v-dialog :value="true" overlay-opacity="0.6" @input="close()" overlay-color="#000" max-width="500px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Enter Promo Code:</h3>
                <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-4 pb-0 px-3>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <v-alert dense class="mx-2" outlined type="error" v-if="errors">
                                {{ errors }}
                            </v-alert>
                            <p class="subtitle-1 font-weight-medium lh-14">Enter your promo code below:</p>
                            <v-text-field class="mx-auto mt-5 mb-0" style="max-width: 200px;" outlined :autofocus="!$vuetify.breakpoint.smAndDown" :rules="[rules.required]" v-model="code" validate-on-blur required placeholder="Ex: Free20" color="primary" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions">
                <v-btn text outlined class="px-3" @click.native="close()">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" :disabled="code.length < 3" class="px-6" @click.native="verify()">Apply Promo</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mapState } from 'vuex'

export default {

    name: 'SubscriptionPromoDialog',
    props: {
    },
    components: {
    },
    computed: {
        ...mapState(['user'])        
    },
    data: () => ({
        code: '',
        errors: null,
        loading: false,
        rules: {
            required: value => !!value || 'Required.',
        },
    }),
    methods: {
        verify(){
            this.loading = true;
            let request = {
                endpoint: '/subscription/checkpromo', 
                method: 'post',
                data: {
                    promo: this.code,
                },
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$emit('addCode', {code: this.code.toUpperCase(), promoId: res.data.code});
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your promo has been applied!'
                    });
                    this.close();
                }else{
                    this.errors = "Invalid or expired code provided. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.loading = false;
            });
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
